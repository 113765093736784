.ticketBox {
  border-radius: 15px;
  border: 1px dashed #f1f1f5;
  background: #ffffff;
  background-blend-mode: normal;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  padding: 29px 47px;
  margin-top: 17px;
}

.boxHeader{
    display: flex;
    justify-content: space-between;
}

.paginationBtn{
    border:1px solid red !important;
}

.hLine {
  border-color: #ececf1;
}

.width25 {
  width: 25%;
  margin-left: 68px !important;
}
.width30{
  width: 30%;
  margin-left: 68px !important;
}
.width20{
  width: 20%;
  margin-left: 68px !important;
}

.headerStyle {
  display: flex;
  height: 50px;
  border-radius: 10px;
  background: #9167ac;
  background-blend-mode: normal;
  align-items: center;
}

.bodyStyle {
  display: flex;
  margin-top: 12px;
  height: 58px;
  border-radius: 10px;
  border: 1px solid #e2e2ea;
  align-items: center;
}

.statusBar {
  height: 24px;
  border-radius: 11px;
  background: #99cb51;
  background-blend-mode: normal;
  text-align: center;
  /* width: 160px; */
}
.subjectName{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
